import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import style from './style.css';
import { setSecure } from '../../reducers/security.js';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

class CoequalChoices extends React.Component {
    static propTypes = {
        /* array of Objects of form: { url: string, linkText: string, icon: object, isSecure: bool } */
        coequalChoices: PropTypes.array.isRequired,

        // From redux
        setSecure: PropTypes.func.isRequired,
    };

    _renderChoice = choice => {
        if (choice.linkText === 'Reports' && Fortress.user.acting?.role_data?.blockReportAccess) {
            return;
        }

        return (
            <a
                className={style.choice}
                onClick={async () => {
                    if (choice.isSecure) {
                        await this.props.setSecure();
                    }
                    window.location = Boilerplate.url(choice.url);
                }}
                key={choice.url}
            >
                {choice.icon && (
                    <span className={`${style.icon} icon`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox={choice.icon.viewBox}>
                            <g>
                                {choice.icon.paths.map(p => (
                                    <path key={p} d={p} />
                                ))}
                            </g>
                        </svg>
                    </span>
                )}
                <div className={style.linkText}>{choice.linkText}</div>
            </a>
        );
    };

    render() {
        return <div className={style.choiceContainer}>{this.props.coequalChoices.map(this._renderChoice)}</div>;
    }
}

export default connect(null, { setSecure })(CoequalChoices);
