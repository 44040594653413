import React from 'react';

/*
 * Displays the appropriate assignment plan label for the student/teacher
 *
 * If the student belongs to a class or teacher belongs to a school that explicitly has an assignment plan set
 * then use that and indicate visually that it's a deviation from the district default
 *
 * otherwise fall back to the district default for either teacher or the student's grade
 *
 * failing all of that, return blank/null
 */
export default settings => {
    return ({ row, isCsv }) => {
        let plans = [];
        let isAsterisk = false;

        let isAssignedAtSchoolLevel = false;
        let isAssignedAtClassLevel = false;
        switch (settings.role) {
            case 'instructor':
                try {
                    isAssignedAtSchoolLevel = row['ancestorGroups.site.selectedAssignmentPlanList'].some(
                        plan => !!plan.label
                    );
                } catch (e) {
                    // meh probably just not defined at school level
                }

                if (isAssignedAtSchoolLevel) {
                    plans = row['ancestorGroups.site.selectedAssignmentPlanList'].map(plan => plan.label);
                    isAsterisk = true;
                } else {
                    // check district-defaults
                    let legacyPlan;
                    let plan;
                    try {
                        const legacyDefaultPlanId =
                            row['ancestorGroups.groupingUnit.group.settings.defaultAssignmentPlans.plan_teacher'];
                        legacyPlan = row['ancestorGroups.groupingUnit.availableAssignmentPlanList'].find(
                            availablePlan =>
                                availablePlan.assignmentPlanId &&
                                Number(availablePlan.assignmentPlanId) === Number(legacyDefaultPlanId)
                        );
                    } catch (e) {
                        // meh, just leave as no plan selected
                    }

                    try {
                        const defaultPlanId =
                            row[
                                'ancestorGroups.groupingUnit.group.settings.defaultAssignmentPlans.plan_studentRatingForm'
                            ];
                        plan = row['ancestorGroups.groupingUnit.availableAssignmentPlanList'].find(
                            availablePlan =>
                                availablePlan.assignmentPlanId &&
                                Number(availablePlan.assignmentPlanId) === Number(defaultPlanId)
                        );
                    } catch (e) {
                        // meh, just leave as no plan selected
                    }

                    if (legacyPlan?.label) {
                        plans.push(legacyPlan.label);
                    }

                    if (plan?.label) {
                        plans.push(plan.label);
                    }
                }
                break;
            case 'learner':
                try {
                    isAssignedAtClassLevel = !!row['ancestorGroups.class.selectedAssignmentPlan'].label;
                } catch (e) {
                    // probably not
                }

                if (isAssignedAtClassLevel) {
                    plans.push(row['ancestorGroups.class.selectedAssignmentPlan'].label);
                    isAsterisk = true;
                } else {
                    let grade = row['fields.grade'];
                    // Kindergarten grade is stored in appdefs as K, but in assignment plan list as "plan_0"
                    if (['k', 'K', '00'].includes(grade)) {
                        grade = '0';
                    }

                    try {
                        const defaultPlanId =
                            row['ancestorGroups.groupingUnit.group.settings.defaultAssignmentPlans.plan_' + grade];
                        const defaultPlan = row['ancestorGroups.groupingUnit.availableAssignmentPlanList'].find(
                            availablePlan =>
                                availablePlan.assignmentPlanId &&
                                Number(availablePlan.assignmentPlanId) === Number(defaultPlanId)
                        );

                        if (defaultPlan?.label) {
                            plans.push(defaultPlan.label);
                        }
                    } catch (e) {
                        // probably just not defined
                    }
                }
                break;
            default:
            // meh *shrug* do nothing
        }

        return isCsv ? (
            plans.join(',')
        ) : (
            <ul className="list-unstyled">
                {plans.map((plan, i) => (
                    <li key={i}>
                        -&nbsp;{plan}
                        {isAsterisk ? ' *' : ''}
                    </li>
                ))}
            </ul>
        );
    };
};
