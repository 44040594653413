import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setInsecure } from '../../reducers/security.js';

class SetInsecureWrapper extends React.Component {
    static propTypes = {
        wrappedComponent: PropTypes.func.isRequired,

        // From redux
        setInsecure: PropTypes.func,
    };

    componentDidMount = () => {
        this.props.setInsecure();
    };

    render() {
        const Component = this.props.wrappedComponent;
        if (!Component) {
            return null;
        }
        let cleanedProps = Object.assign({}, this.props);
        delete cleanedProps.wrappedComponent;

        return <Component {...cleanedProps} />;
    }
}

export default connect(null, { setInsecure })(SetInsecureWrapper);
