import React from 'react';
import _get from 'lodash/get';
import { client, gql } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { useQuery } from 'cccisd-react-query';
import Table from 'cccisd-table';

import { RATING_FORM_ASSIGNMENT_HANDLE } from '../TeacherAdministrative';

const TeacherTableByGroup = ({ columns, groupPath, name, parentPath, query, timepoint }) => {
    // don't show `null` in csv downloads
    columns = columns.map(c => ({
        ...c,
        renderCSV: value => {
            return ['null', 'undefined', null, undefined].includes(value) ? '' : value;
        },
        // percent column should have numeric value for sorting but display as string
        ...(c.name === 'percent' ? { render: val => val + '%' } : {}),
    }));

    const { data, isLoading } = useQuery(
        [name + timepoint],
        async () => {
            const resp = await client.query({
                query: gql`
                    ${query}
                `,
                variables: {
                    timepoint,
                },
                fetchPolicy: 'network-only',
            });

            const teachers = _get(resp, 'data.roles.instructorList', []) || [];

            // schoolMapper used to keep track of which schools have
            // legacyAssignmentPlans and new-style assignment plans
            const schoolMapper = {};
            // dataMapper used to set data efficiently without nested loops
            // Will transpose this into column data later
            const dataMapper = {};

            teachers.forEach(teacher => {
                const group = _get(teacher, groupPath);
                if (!group?.group?.groupId) {
                    return; // continue
                }

                const groupId = group.group.groupId;
                const fields = group.fields || {};

                if (!dataMapper[groupId]) {
                    dataMapper[groupId] = {
                        label: group.group.label,
                        parent: _get(teacher, parentPath)?.group?.label,
                        total: 0,
                        unassigned: 0,
                        started: 0,
                        completed: 0,
                        ...fields,
                    };
                }

                const [isTeacherUnassigned, isTeacherStarted, isTeacherCompleted] = getTeacherStatus(
                    teacher,
                    schoolMapper
                );

                dataMapper[groupId].total++;
                if (isTeacherUnassigned) {
                    dataMapper[groupId].unassigned++;
                }
                if (isTeacherStarted) {
                    dataMapper[groupId].started++;
                }
                if (isTeacherCompleted) {
                    dataMapper[groupId].completed++;
                }
            });

            return dataMapper;
        },
        {
            refetchOnWindowFocus: false,
        }
    );

    function getTeacherStatus(teacher, schoolMapper) {
        const siteId = teacher?.ancestorGroups?.site?.group?.groupId;
        if (!siteId) {
            return [true, false, false];
        }

        const alreadyExistsInMapper = !!schoolMapper[siteId];

        let isRatingForm = false;
        let isLegacyRatingForm = false;
        // If we already did a teacher from this school
        if (alreadyExistsInMapper) {
            isRatingForm = schoolMapper[siteId].isRatingForm;
            isLegacyRatingForm = schoolMapper[siteId].isLegacyRatingForm;
        }
        // if it's the first teacher from this school
        else {
            let isSchoolLevelPlan = false;
            try {
                isSchoolLevelPlan = teacher.ancestorGroups.site.selectedAssignmentPlanList.some(
                    plan => plan.assignmentPlanId
                );
            } catch (e) {
                // probably just undefined
                isSchoolLevelPlan = false;
            }

            if (isSchoolLevelPlan) {
                try {
                    isLegacyRatingForm = teacher.ancestorGroups.site.selectedAssignmentPlanList.some(plan =>
                        plan.sessionList.some(
                            sess =>
                                sess.deployment.assignment.assignmentHandle !== RATING_FORM_ASSIGNMENT_HANDLE &&
                                parseInt(sess.timepoint, 10) === parseInt(timepoint, 10)
                        )
                    );
                } catch (e) {
                    // probably not
                    isLegacyRatingForm = false;
                }

                try {
                    isRatingForm = teacher.ancestorGroups.site.selectedAssignmentPlanList.some(plan =>
                        plan.sessionList.some(
                            sess =>
                                sess.deployment.assignment.assignmentHandle === RATING_FORM_ASSIGNMENT_HANDLE &&
                                parseInt(sess.timepoint, 10) === parseInt(timepoint, 10)
                        )
                    );
                } catch (e) {
                    // probably not
                    isRatingForm = false;
                }
            }
            // Look for district-default assignment plans
            else {
                try {
                    const legacyDefaultPlanId =
                        teacher.ancestorGroups.groupingUnit.group.settings.defaultAssignmentPlans.plan_teacher;

                    const legacySelectedPlan = teacher.ancestorGroups.organization.createdAssignmentPlanList.find(
                        plan => parseInt(plan.assignmentPlanId, 10) === parseInt(legacyDefaultPlanId, 10)
                    );

                    isLegacyRatingForm = legacySelectedPlan.sessionList.some(
                        sess =>
                            sess.deployment.assignment.assignmentHandle !== RATING_FORM_ASSIGNMENT_HANDLE &&
                            parseInt(sess.timepoint, 10) === parseInt(timepoint, 10)
                    );
                } catch (e) {
                    // probably not
                    isLegacyRatingForm = false;
                }

                try {
                    const defaultPlanId =
                        teacher.ancestorGroups.groupingUnit.group.settings.defaultAssignmentPlans
                            .plan_studentRatingForm;

                    const selectedPlan = teacher.ancestorGroups.organization.createdAssignmentPlanList.find(
                        plan => parseInt(plan.assignmentPlanId, 10) === parseInt(defaultPlanId, 10)
                    );

                    isRatingForm = selectedPlan.sessionList.some(
                        sess =>
                            sess.deployment.assignment.assignmentHandle === RATING_FORM_ASSIGNMENT_HANDLE &&
                            parseInt(sess.timepoint, 10) === parseInt(timepoint, 10)
                    );
                } catch (e) {
                    // probably not
                    isRatingForm = false;
                }
            }

            schoolMapper[siteId] = {
                isRatingForm,
                isLegacyRatingForm,
            };
        }

        let isLegacyFormStarted = !isLegacyRatingForm || !!teacher.legacyRatingFormProgress.startedDate;
        let isLegacyFormCompleted = !isLegacyRatingForm || !!teacher.legacyRatingFormProgress.completedDate;

        let isRatingFormStarted;
        let isRatingFormCompleted;
        try {
            const classList = teacher.ratingFormProgress.classList;
            isRatingFormStarted = !isRatingForm || classList.some(c => c?.childRoles?.existsStarted?.pawn?.pawnId);
            isRatingFormCompleted =
                !isRatingForm || !classList.some(c => c?.childRoles?.existsNotCompleted?.pawn?.pawnId);
        } catch (e) {
            isRatingFormStarted = !isRatingForm || false;
            isRatingFormCompleted = !isRatingForm || false;
        }

        let isUnassigned = !isLegacyRatingForm && !isRatingForm;
        let isStarted = !isUnassigned && (isLegacyFormStarted || isRatingFormStarted);
        let isCompleted = !isUnassigned && isLegacyFormCompleted && isRatingFormCompleted;

        return [isUnassigned, isStarted, isCompleted];
    }

    if (isLoading || !data) {
        return <Loader loading />;
    }

    return (
        <Table
            name={name}
            columns={columns}
            data={Object.keys(data).map(id => {
                const percent = Math.round((data[id].completed / (data[id].total - data[id].unassigned)) * 100);

                return {
                    id,
                    ...data[id],
                    percent: Number.isNaN(percent) ? 0 : percent,
                };
            })}
            isCsvDownload
        />
    );
};

export default TeacherTableByGroup;
