import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { parseISO, isAfter, compareDesc } from 'date-fns';
import { client as apollo } from 'cccisd-apollo';
import Axios from 'cccisd-axios';
import IconPlus from 'cccisd-icons/plus2';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';
import ClickButton from 'cccisd-click-button';
import IconBin from 'cccisd-icons/bin';
import IconFile from 'cccisd-icons/files-empty2';
import IconPencil from 'cccisd-icons/pencil';
import Tooltip from 'cccisd-tooltip';
import EditForm from './EditForm.js';
import reportsQuery from './reports.graphql';
import Form from './Form.js';
import ReportTable from './ReportTable.js';
import styles from './styles.css';

const Boilerplate = window.cccisd.boilerplate;
const Appdefs = window.cccisd.appDefs;

const ReportAdmin = ({ projectId }) => {
    const schoolYears = _get(Appdefs, 'app.schoolYears', []);
    const isoRe = /[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
    if (!schoolYears.every(({ startISO, endISO }) => isoRe.test(startISO) && (!endISO || isoRe.test(endISO)))) {
        console.error('School years defined in appdefs should be in ISO date format YYYY-MM-DD');
    }

    const [loading, setLoading] = useState(true);
    const [reportData, setReportData] = useState([]);
    const [existingReportNames, setExistingReportNames] = useState(null);

    /* /////////////////////////////////////////////////////////////////////////
    // LIFECYCLE FUNCTIONS ////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    useEffect(() => {
        loadData();
    }, []);

    /* /////////////////////////////////////////////////////////////////////////
    // PUBLIC FUNCTIONS ///////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    async function deleteReport(row) {
        await Axios.delete(
            Boilerplate.route('app.reports.destroy', {
                projectId,
                reportName: row.originalReportName,
            })
        );
        loadData();
    }

    async function loadData() {
        setLoading(true);
        const result = await apollo.query({
            query: reportsQuery,
            fetchPolicy: 'network-only',
            variables: {
                projectId: `${projectId}`,
            },
        });
        let reportNames = [];
        let newReportData = schoolYears
            .sort((a, b) => compareDesc(parseISO(a.startISO), parseISO(b.startISO))) // recent first
            .map(year => ({ ...year, reports: [] }));

        const reportFolderList = _get(result, 'data.resources.reportFolderList', []);
        const districtFolderList = _get(result, 'data.resources.districtFolderList', []);
        const schoolFolderList = _get(result, 'data.resources.schoolFolderList', []);
        const classFolderList = _get(result, 'data.resources.classFolderList', []);

        reportFolderList.forEach(f => {
            const reportName = f.path.replace(/\/private\/selweb_reports\/[0-9]+\//, '');
            reportNames.push(reportName);
            const reportDate = f.createdDate ? parseISO(f.createdDate) : new Date();

            let fileList = [];
            function addToFileList(folder) {
                if (!folder.fileList || !folder.fileList.length) {
                    return;
                }

                const folderPath = folder.path.slice(folder.path.indexOf(`${reportName}/`) + reportName.length + 1);
                folder.fileList.forEach(file => fileList.push(folderPath + '/' + file.filename));
            }

            let districtCount = 0;
            let schoolCount = 0;
            let classCount = 0;
            districtFolderList.forEach(d => {
                if (d.path.startsWith(f.path + '/')) {
                    districtCount += 1;
                    addToFileList(d);
                }
            });
            schoolFolderList.forEach(s => {
                if (s.path.startsWith(f.path + '/')) {
                    schoolCount += 1;
                    addToFileList(s);
                }
            });
            classFolderList.forEach(c => {
                if (c.path.startsWith(f.path + '/')) {
                    classCount += 1;
                    addToFileList(c);
                }
            });

            let yearIndex = newReportData.findIndex(year => isAfter(reportDate, parseISO(year.startISO)));
            if (yearIndex === -1) yearIndex = newReportData.length - 1;

            newReportData[yearIndex].reports.push({
                path: f.path,
                originalReportName: reportName,
                folderId: f.folderId,
                reportName: reportName.replace(/_/g, ' '),
                districtCount,
                schoolCount,
                classCount,
                fileList: fileList.sort(),
            });
        });

        setReportData(newReportData);
        setExistingReportNames(reportNames);
        setLoading(false);
    }

    /* /////////////////////////////////////////////////////////////////////////
    // RENDER-RELATED /////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    const renderAddReportButton = (
        <Modal
            title="Upload your report .zip file"
            trigger={
                <button type="button" className="btn btn-primary">
                    <IconPlus />
                    &nbsp;&nbsp;Add Report
                </button>
            }
            render={({ closeModal }) => {
                const onSuccess = () => {
                    loadData();
                    closeModal();
                };
                return <Form existingReportNames={existingReportNames} projectId={projectId} onSuccess={onSuccess} />;
            }}
        />
    );

    const renderActions = row => (
        <div>
            <Modal
                title={`Edit ${row.reportName}`}
                trigger={
                    <Tooltip title="Rename">
                        <button className={`btn btn-xs btn-success ${styles.buttonSpacing}`} type="button">
                            <IconPencil />
                        </button>
                    </Tooltip>
                }
                render={({ closeModal }) => {
                    const onSuccess = () => {
                        loadData();
                        closeModal();
                    };
                    return (
                        <EditForm
                            onSuccess={onSuccess}
                            existingReportNames={existingReportNames}
                            projectId={projectId}
                            row={row}
                        />
                    );
                }}
            />
            <ClickButton
                className={'btn btn-xs btn-danger ' + styles.buttonSpacing}
                confirmationMessage={`Are you sure you want to delete '${row.reportName}'?`}
                isConfirm
                onClick={() => deleteReport(row)}
                title={
                    <Tooltip title="Delete">
                        <IconBin />
                    </Tooltip>
                }
            />
            <Modal
                title={`File List - ${row.reportName}`}
                trigger={
                    <Tooltip title="File List">
                        <button className="btn btn-xs btn-warning" type="button">
                            <IconFile />
                        </button>
                    </Tooltip>
                }
                render={({ closeModal }) => (
                    <div className={styles.fileList}>
                        <p>
                            <b>
                                This report has {row.fileList.length} file
                                {row.fileList.length === 1 ? '' : 's'}.
                            </b>
                        </p>
                        <ul>
                            {row.fileList.map((file, i) => (
                                <li key={i}>{file}</li>
                            ))}
                        </ul>
                        <button type="button" className="btn btn-primary" onClick={() => closeModal()}>
                            Close
                        </button>
                    </div>
                )}
            />
        </div>
    );

    /* /////////////////////////////////////////////////////////////////////////
    // RENDER /////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    return (
        <Loader loading={loading} removeChildren={!reportData}>
            <div>
                {renderAddReportButton}
                <ul className={styles.tableList}>
                    {reportData.map((year, i) => (
                        <ReportTable year={year} key={year.startISO} renderActions={renderActions} />
                    ))}
                </ul>
            </div>
        </Loader>
    );
};

ReportAdmin.propTypes = {
    projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default ReportAdmin;
