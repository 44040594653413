import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import { connect } from 'react-redux';

const Boilerplate = window.cccisd.boilerplate;

class RequireSecureWrapper extends React.Component {
    static propTypes = {
        wrappedComponent: PropTypes.func.isRequired,

        // From redux
        isSecure: PropTypes.bool,
    };

    state = {
        loading: true,
    };

    componentDidMount = () => {
        if (!this.props.isSecure) {
            window.location = Boilerplate.url('/api/user/logout');
        }
        this.setState({ loading: false });
    };

    render() {
        if (this.state.loading) {
            return <Loader loading />;
        }
        const Component = this.props.wrappedComponent;
        if (!Component) {
            return null;
        }
        let cleanedProps = Object.assign({}, this.props);
        delete cleanedProps.wrappedComponent;
        delete cleanedProps.isSecure;

        return <Component {...cleanedProps} />;
    }
}

const mapStateToProps = state => ({
    isSecure: state.app.security.isSecure,
});

export default connect(mapStateToProps)(RequireSecureWrapper);
