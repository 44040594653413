import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _omit from 'lodash/omit';
import Axios from 'cccisd-axios';
import InfoIcon from 'cccisd-icons/info2';
import Tooltip from 'cccisd-tooltip';
import styles from './styles.css';

const Boilerplate = window.cccisd.boilerplate;

const EditForm = ({ existingReportNames, onSuccess, row }) => {
    const [reportName, setReportName] = useState('');
    const [error, setError] = useState({});

    function reportExists(name) {
        if (existingReportNames.includes(name)) {
            setError({ reportName: ['This report name already exists'] });
            return true;
        }

        return false;
    }

    async function handleSubmit() {
        if (!reportName) {
            setError({ reportName: ['Report Name is required'] });
            return;
        }
        if (reportExists(reportName)) {
            return;
        }
        try {
            const response = await Axios.put(
                Boilerplate.route('api.resources.folder.update', { folder: row.folderId }),
                { path: row.path.replace(row.originalReportName, reportName) }
            );
            if (response.data.errors) {
                setError(response.data.errors);
            } else {
                setError({});
                setReportName('');
                onSuccess();
            }
        } catch (e) {
            console.error(e);
        }
    }

    function showErrors() {
        const otherErrors = _omit(error, ['reportName']);
        if (!Object.keys(otherErrors).length) {
            return null;
        }
        return (
            <div className={classnames(styles.generalErrorContainer, 'bg-danger')}>
                {Object.keys(otherErrors).map((k, i) => {
                    return (
                        <div className="text-danger" key={i}>
                            {error[k].join(', ')}
                        </div>
                    );
                })}
            </div>
        );
    }

    /* /////////////////////////////////////////////////////////////////////////
    // RENDER-RELATED /////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    return (
        <div>
            <div className={classnames(styles.formField, error.reportName && 'has-error')}>
                <label className="control-label">Report Name</label>{' '}
                <Tooltip title="Only letters, numbers, hyphens, and underscores allowed">
                    <span className="text-primary">
                        <InfoIcon />
                    </span>
                </Tooltip>
                <input
                    className="form-control"
                    onChange={e => {
                        const reportNameInput = e.target.value.replace(/ /g, '_').replace(/[^a-zA-Z0-9_-]/g, '');
                        setReportName(reportNameInput);
                        if (!reportExists(reportNameInput)) {
                            setError({ ...error, reportName: undefined });
                        }
                    }}
                    type="text"
                    value={reportName}
                />
                {!!error.reportName && <div className="help-block">{error.reportName.join(', ')}</div>}
            </div>
            {showErrors()}
            <button
                type="button"
                className={`btn btn-primary ${styles.submit}`}
                disabled={!!error.reportName}
                onClick={handleSubmit}
            >
                Rename
            </button>
        </div>
    );
};

EditForm.propTypes = {
    existingReportNames: PropTypes.array,
    onSuccess: PropTypes.func.isRequired,
    row: PropTypes.object.isRequired,
};

EditForm.defaultProps = {
    existingReportNames: [],
};

export default EditForm;
