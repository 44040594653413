import React from 'react';
import style from './style.css';
import { Link } from 'react-router-dom';
import { client, gql } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import _get from 'lodash/get';
import NotificationIcon from 'cccisd-icons/notification';
import Administrative from '../Administrative';
import studentRatingFormQuery from './studentRatingForm.graphql';

export const LEGACY_RATING_FORM_ASSIGNMENT_HANDLE = 'rating_form';
export const RATING_FORM_ASSIGNMENT_HANDLE = 'studentRatingForm';

export default class TeacherAdminstrative extends React.Component {
    state = {
        isLoading: true,
        classList: [],
        selectedClassId: '',
        legacyRatingForms: '', // one of ['', 'complete', or 'incomplete']
        teacherRatingForms: '', // one of ['', 'complete', or 'incomplete']

        // all archived reports show in Reports tab regardless of
        // which class is currently selected, so only fetch once
        archivedReportFileList: [],
    };

    componentDidMount() {
        this.fetchInitialData();
    }

    fetchInitialData = async () => {
        await this.fetchClassList();
        await this.fetchArchivedReportFileList();
        this.setState({ isLoading: false });
    };

    fetchClassList = async () => {
        const { classListQuery } = this.props;
        const response = await client.query({
            query: gql`
                ${classListQuery}
            `,
            fetchPolicy: 'network-only',
        });

        const classList = _get(response, 'data.groups.classList', []);
        const legacyRatingForms = await this.processIsRatingForm(response, LEGACY_RATING_FORM_ASSIGNMENT_HANDLE);
        const teacherRatingForms = await this.processIsRatingForm(response, RATING_FORM_ASSIGNMENT_HANDLE);
        const selectedClassId = classList.length > 0 ? classList[0].group.groupId : '';
        this.setState({ legacyRatingForms, teacherRatingForms, classList, selectedClassId });
    };

    fetchArchivedReportFileList = async () => {
        const { archivedClassQuery } = this.props;
        const response = await client.query({
            query: gql`
                ${archivedClassQuery}
            `,
            fetchPolicy: 'network-only',
        });

        let archivedClassIds = _get(response, 'data.groups.classList', []) || [];
        archivedClassIds = archivedClassIds.map(c => c.group.groupId);

        const archivedReports = await Promise.all(archivedClassIds.map(classId => this.fetchArchivedReports(classId)));

        const archivedReportFileList = [];
        archivedReports.forEach(list => list.forEach(file => archivedReportFileList.push(file)));
        this.setState({ archivedReportFileList });
    };

    fetchArchivedReports = async classId => {
        const { fileQuery } = this.props;

        const resp = await client.query({
            query: gql`
                ${fileQuery}
            `,
            variables: { classId },
            fetchPolicy: 'network-only',
        });

        return _get(resp, 'data.resources.fileList', []) || [];
    };

    processIsRatingForm = async (classListResponse, assignmentHandle) => {
        let isSchoolLevelPlan = false;
        try {
            isSchoolLevelPlan = classListResponse.data.groups.site.selectedAssignmentPlanList.some(
                plan => plan.assignmentPlanId
            );
        } catch (e) {
            // meh *shrugs*
        }

        let currentSession;
        if (isSchoolLevelPlan) {
            try {
                currentSession = classListResponse.data.groups.site.selectedAssignmentPlanList.find(
                    plan =>
                        plan.assignmentPlanId &&
                        plan.currentSession.sessionId &&
                        plan.currentSession.deployment.assignment.assignmentHandle === assignmentHandle
                ).currentSession;
            } catch (e) {
                // met *shrugs*
            }
        }

        const districtLevelPlanId = _get(
            classListResponse,
            assignmentHandle === LEGACY_RATING_FORM_ASSIGNMENT_HANDLE
                ? 'data.groups.groupingUnit.group.settings.defaultAssignmentPlans.plan_teacher'
                : 'data.groups.groupingUnit.group.settings.defaultAssignmentPlans.plan_studentRatingForm',
            false
        );

        if (!isSchoolLevelPlan && districtLevelPlanId) {
            const districtLevelPlan = (
                _get(classListResponse, 'data.groups.organization.createdAssignmentPlanList', []) || []
            ).find(plan => parseInt(plan.assignmentPlanId, 10) === parseInt(districtLevelPlanId, 10));

            currentSession = districtLevelPlan?.currentSession;
        }

        if (!currentSession?.deploymentId) {
            return '';
        }

        if (assignmentHandle === LEGACY_RATING_FORM_ASSIGNMENT_HANDLE) {
            return _get(currentSession, 'deployment.assignmentProgress.completed', false) ? 'complete' : 'incomplete';
        }

        // complete status for student rating form depends on completion of all students
        const resp = await client.query({
            query: studentRatingFormQuery,
            variables: { deploymentId: currentSession.deploymentId },
            fetchPolicy: 'network-only',
        });

        const learner = resp.data.roles?.learner;
        const isComplete = !learner?.pawn?.pawnId;
        return isComplete ? 'complete' : 'incomplete';
    };

    setClassId = selectedClassId => {
        this.setState({ selectedClassId });
    };

    render() {
        const { legacyRatingForms, teacherRatingForms, classList, selectedClassId, isLoading, archivedReportFileList } =
            this.state;

        return (
            <Loader loading={isLoading}>
                {[legacyRatingForms, teacherRatingForms].map((status, i) => {
                    if (status === 'incomplete') {
                        const [link, text] =
                            i === 0
                                ? ['/teacherSurveys', 'Teacher Surveys']
                                : ['/studentRatingForm', 'Teacher Rating Scale'];
                        return (
                            status === 'incomplete' && (
                                <p className={style.ratingFormBanner} key={i}>
                                    <span className={style.icon}>
                                        <NotificationIcon />
                                    </span>
                                    &nbsp; Time to complete &nbsp;<Link to={link}>{text}</Link>
                                </p>
                            )
                        );
                    }
                    if (status === 'complete') {
                        const text = i === 0 ? 'Teacher Surveys' : 'Teacher Rating Scale';
                        return (
                            status === 'complete' && (
                                <div className={style.ratingFormComplete} key={i}>
                                    <span className=" glyphicon glyphicon-ok" /> {text} Completed
                                </div>
                            )
                        );
                    }
                    return null;
                })}
                {classList.length > 1 && (
                    <select
                        name="classList"
                        id="classList"
                        value={selectedClassId}
                        onChange={event => this.setClassId(parseInt(event.target.value, 10))}
                        className={style.classSelect}
                    >
                        {!selectedClassId && (
                            <option value="" key="placeholder">
                                Select a class
                            </option>
                        )}
                        {classList.map(c => (
                            <option value={c.group.groupId} key={c.group.groupId}>
                                {c.group.label}
                            </option>
                        ))}
                    </select>
                )}
                {!isLoading && (
                    <Administrative
                        key={selectedClassId}
                        {...this.props}
                        classId={selectedClassId}
                        archivedReportFileList={archivedReportFileList}
                    />
                )}
            </Loader>
        );
    }
}
