import React from 'react';
import LoginForm from 'cccisd-laravel-nexus/es/components/LoginForm';
var Fortress = window.cccisd.fortress;
var AppDefs = window.cccisd.appDefs;
import Style from './style.css';
import { Html } from 'cccisd-wysiwyg';

export default class Component extends React.Component {
    componentDidMount() {
        if (Fortress.auth()) {
            window.location = window.cccisd.boilerplate.url('authRedirect');
        }
    }

    render() {
        return (
            <div className="landingPage" style={{ marginTop: '20px' }}>
                <div className="welcomeLogin">
                    <div className="row">
                        <div className="col-md-8">
                            <div>
                                <h1>
                                    <b>Welcome to SELweb</b>
                                </h1>
                            </div>
                            <div className={Style.welcomeText}>
                                <p>
                                    <Html content={AppDefs.app.siteContent.landingPage} />
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            {!Fortress.auth() && (
                                <LoginForm showPasswordToggle showPasswordByDefault={false} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
