import React, { useState } from 'react';
import Table from 'cccisd-table';
import IconRight from 'cccisd-icons/arrow-right5';
import IconDown from 'cccisd-icons/arrow-down5';
import style from './styles.css';

const ReportTable = ({ year, renderActions }) => {
    const [isExpanded, setIsExpanded] = useState(!year.endISO);

    const columns = [
        {
            name: 'actions',
            label: 'Actions',
            filter: false,
            sort: false,
            render: (_, row) => {
                return renderActions(row);
            },
            class: style.actionsColumn,
        },
        {
            name: 'reportName',
            label: 'Report Name',
            filter: true,
            sort: true,
        },
        {
            name: 'districtCount',
            label: 'Districts',
            filter: true,
            sort: true,
        },
        {
            name: 'schoolCount',
            label: 'Schools',
            filter: true,
            sort: true,
        },
        {
            name: 'classCount',
            label: 'Classrooms',
            filter: true,
            sort: true,
        },
    ];

    function getHeaderText() {
        if (year.label) {
            return year.label;
        }

        return (
            `${year.startISO.slice(0, 4)}-${year.endISO ? year.endISO.slice(0, 4) : 'Current'}` +
            ' Reports'
        );
    }

    return (
        <li className={style.tableWrapper} key={year.startISO}>
            <h6>
                <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => setIsExpanded(prev => !prev)}
                >
                    {isExpanded ? <IconDown /> : <IconRight />}&nbsp;&nbsp;
                    {getHeaderText()}
                </button>
            </h6>
            {isExpanded && (
                <div className={style.table}>
                    <Table
                        columns={columns}
                        data={year.reports}
                        rowKey="folderId"
                        noRecordsMessage="No reports for this timespan"
                    />
                    <button
                        type="button"
                        className={'btn btn-link btn-xs ' + style.collapseButton}
                        onClick={() => setIsExpanded(false)}
                    >
                        Collapse Table
                    </button>
                </div>
            )}
        </li>
    );
};

export default ReportTable;
