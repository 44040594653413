import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useHistory } from 'cccisd-react-router';
import { client } from 'cccisd-apollo';
import Table from 'cccisd-graphql-table';
import IconCheck from 'cccisd-icons/checkmark4';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';
import assignmentPlanQuery from './assignmentPlan.graphql';
import studentsQuery from './students.graphql';

import { RATING_FORM_ASSIGNMENT_HANDLE } from '../TeacherAdministrative';

const TeacherStudentRatingForm = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [currentSession, setCurrentSession] = useState(null);
    const history = useHistory();

    useEffect(() => {
        async function getData() {
            const resp = await client.query({
                query: assignmentPlanQuery,
                variables: {},
                fetchPolicy: 'network-only',
            });

            let isSchoolLevel;
            try {
                isSchoolLevel = resp.data.groups.site.selectedAssignmentPlanList.some(plan => plan.assignmentPlanId);
            } catch (e) {
                // probably undefined
                isSchoolLevel = false;
            }

            let session;
            if (isSchoolLevel) {
                try {
                    session = resp.data.groups.site.selectedAssignmentPlanList.find(
                        plan =>
                            plan.assignmentPlanId &&
                            plan.currentSession.sessionId &&
                            plan.currentSession.deployment.assignment.assignmentHandle === RATING_FORM_ASSIGNMENT_HANDLE
                    ).currentSession;
                } catch (e) {
                    // probably undefined assignment plan or session
                }
            } else {
                // if not assigned at School level, look for District-default
                try {
                    const defaultPlanId =
                        resp.data.groups.groupingUnit.group.settings.defaultAssignmentPlans.plan_studentRatingForm;

                    session = resp.data.groups.organization.createdAssignmentPlanList.find(
                        plan =>
                            defaultPlanId &&
                            plan.assignmentPlanId &&
                            parseInt(plan.assignmentPlanId, 10) === parseInt(defaultPlanId, 10) &&
                            plan.currentSession.sessionId &&
                            plan.currentSession.deployment.assignment.assignmentHandle === RATING_FORM_ASSIGNMENT_HANDLE
                    ).currentSession;
                } catch (e) {
                    // probably just undefined assignment plan or currentSession
                }
            }

            setCurrentSession(session);
            setIsLoading(false);
        }

        getData();
    }, []);

    function renderRatingFormColumn({ row, loadData }) {
        if (row['assignmentProgress.completed']) {
            return (
                <span>
                    <span className="text-success">
                        <IconCheck spaceRight />
                    </span>
                    Done
                </span>
            );
        }

        const isStarted = row['assignmentProgress.started'];

        return (
            <Modal
                title={'Student Rating Form: ' + row['user.username']}
                trigger={
                    isStarted ? (
                        <button className="btn btn-info" type="button">
                            Resume
                        </button>
                    ) : (
                        <button className="btn btn-primary" type="button">
                            Start
                        </button>
                    )
                }
                render={() => {
                    return (
                        <DeploymentPlayer
                            assignmentOptions={currentSession.settings.options}
                            deploymentId={currentSession.deploymentId}
                            disableLayout
                            pawnHash={row['pawn.pawnHash']}
                            pawnId={row['pawn.pawnId']}
                        />
                    );
                }}
                size="large"
                afterClose={() => loadData()}
            />
        );
    }

    if (isLoading) {
        return <Loader loading />;
    }

    if (!currentSession || !currentSession.deploymentId) {
        return (
            <div className="alert alert-warning">
                <p>No Student Rating Forms to complete at this time.</p>
            </div>
        );
    }

    const columns = [
        { name: 'user.firstName', label: 'First Name', sort: true, filter: true },
        { name: 'user.lastName', label: 'Last Name', sort: true, filter: true },
        { name: 'fields.studentId', label: 'Student ID', sort: true, filter: true },
        { name: 'user.username', label: 'Username', sort: true, filter: true },
        { name: 'parentGroup.class.group.label', label: 'Class', sort: true, filter: true },
        {
            name: 'assignmentProgress.status',
            label: 'Student Rating Form',
            render: renderRatingFormColumn,
            className: 'text-center',
            sort: true,
            filter: true,
            filterSettings: {
                type: 'selectbox',
                options: [
                    { value: 'Not Started', label: 'Not Started' },
                    { value: 'In Progress', label: 'In Progress' },
                    { value: 'Complete', label: 'Complete' },
                ],
            },
        },
    ];

    const homeButton = className => (
        <button
            className={className}
            type="button"
            onClick={() => {
                history.push('/choice');
            }}
        >
            Return Home
        </button>
    );

    return (
        <>
            {currentSession.closesDate ? (
                <div className="alert alert-info">
                    <p>
                        Please complete all Student Rating Forms before{' '}
                        <b>{format(new Date(currentSession.closesDate), 'EEEE, MMMM do')}</b>.
                    </p>
                    <br />
                    {homeButton('btn btn-info')}
                </div>
            ) : (
                <>
                    <div>{homeButton('btn btn-primary')}</div>
                    <br />
                </>
            )}

            <Table
                name="teacherStudentRatingForm"
                rowKey="pawn.pawnId"
                query={studentsQuery}
                graphqlVariables={{ deploymentId: currentSession.deploymentId }}
                columns={columns}
            />
        </>
    );
};

export default TeacherStudentRatingForm;
