import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { addMinutes, format } from 'date-fns';
import classnames from 'classnames';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import Dropzone from 'react-dropzone';
import Axios from 'cccisd-axios';
import IconCheckmark from 'cccisd-icons/checkmark4';
import InfoIcon from 'cccisd-icons/info2';
import Tooltip from 'cccisd-tooltip';
import styles from './styles.css';

const Boilerplate = window.cccisd.boilerplate;

const Form = ({ existingReportNames, onSuccess, projectId }) => {
    const [file, setFile] = useState(null);
    const [reportName, setReportName] = useState('');
    const [confirmChecked, setConfirmChecked] = useState(false);
    const [error, setError] = useState({});
    const [uploadProgress, setUploadProgress] = useState(null);
    const [isExists, setIsExists] = useState(false);

    useEffect(() => {
        setIsExists(existingReportNames.includes(reportName));
        setConfirmChecked(false);
        setError(prev => ({ ...prev, reportName: '', confirmChecked: '' }));
    }, [reportName]);

    function onFileDrop(accepted) {
        if (!accepted.length) return;
        setFile(accepted[0]);
        setError({ reportName: error.reportName });
    }

    async function handleSubmit() {
        if (!reportName) {
            setError({ reportName: ['Report Name is required'] });
            return;
        }
        if (isExists && !confirmChecked) {
            setError({ confirmChecked: ['Required'] });
            return;
        }

        const config = {
            headers: { 'Content-Type': undefined },
            onUploadProgress,

            // axios timeout is 30 minutes,
            // but it will get 504 from backend after 1 minute, anyway
            // TO-DO: increase max_execution_time php variable or turn this axios call into a Task Master
            timeout: 1000 * 60 * 30,
        };

        let data = new FormData();
        data.append('file', file);
        data.append('reportName', reportName);
        data.append('projectId', projectId);
        try {
            const response = await Axios.post(Boilerplate.route('app.reports.store'), data, config);
            if (_get(response, 'data.errors')) {
                setError(response.data.errors);
            } else {
                setError({});
                setFile(null);
                setReportName('');
                onSuccess();
            }
            setUploadProgress(null);
        } catch (e) {
            if (e.message.includes('504')) {
                setError({ isTimedOut: true });
            } else {
                console.error(e);
                setUploadProgress(null);
            }
        }
    }

    function onUploadProgress(progressEvent) {
        setUploadProgress((progressEvent.loaded / progressEvent.total) * 100);
    }

    function showErrors() {
        const otherErrors = _omit(error, ['reportName', 'confirmChecked']);
        if (!Object.keys(otherErrors).length) {
            return null;
        }
        return (
            <div className={classnames(styles.generalErrorContainer, 'bg-danger')}>
                {Object.keys(otherErrors).map((k, i) => {
                    return (
                        <div className="text-danger" key={i}>
                            {error[k].join(', ')}
                        </div>
                    );
                })}
            </div>
        );
    }

    /* /////////////////////////////////////////////////////////////////////////
    // RENDER-RELATED /////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    if (uploadProgress) {
        return (
            <>
                <p>
                    Upload Progress: <span className={styles.uploadProgress}>{Math.round(uploadProgress)}%</span>
                </p>
                {uploadProgress === 100 && (
                    <p>The backend is currently saving the zip file so it can kick off the processing job...</p>
                )}
                {error.isTimedOut && (
                    <div className="alert alert-warning">
                        <p>
                            The network request has timed out, but the report is still processing. Please check back at
                            &nbsp;{format(addMinutes(new Date(), 30), 'h:mm bb')} to make sure the report is visible.
                        </p>
                    </div>
                )}
            </>
        );
    }

    if (file) {
        return (
            <div>
                <button className={`btn btn-default ${styles.removeFile}`} onClick={() => setFile(null)} type="button">
                    Remove <span className="text-primary">{file.name}</span>
                </button>
                <div className={classnames(styles.formField, error.reportName && 'has-error')}>
                    <label className="control-label">Report Name</label>{' '}
                    <Tooltip title="Only letters, numbers, hyphens, and underscores allowed">
                        <span className="text-primary">
                            <InfoIcon />
                        </span>
                    </Tooltip>
                    <input
                        className="form-control"
                        onChange={e => {
                            const reportNameInput = e.target.value.replace(/ /g, '_').replace(/[^a-zA-Z0-9_-]/g, '');
                            setReportName(reportNameInput);
                        }}
                        type="text"
                        value={reportName}
                    />
                    {!!error.reportName && <div className="help-block">{error.reportName.join(', ')}</div>}
                </div>
                {reportName &&
                    (isExists ? (
                        <div className={'checkbox ' + (error.confirmChecked ? styles.checkboxHasError : '')}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={confirmChecked}
                                    onChange={e => {
                                        setConfirmChecked(e.target.checked);
                                        setError(prev => ({ ...prev, confirmChecked: '' }));
                                    }}
                                />
                                Report &quot;{reportName}&quot; already exists. No files will be deleted, but files that
                                exist in both reports will be overwritten with the newer version. Please confirm that
                                you want to proceed.
                            </label>
                            {!!error.confirmChecked && (
                                <div className={'text-danger ' + styles.confirmError}>
                                    {error.confirmChecked.join(', ')}
                                </div>
                            )}
                        </div>
                    ) : (
                        <p className={'text-muted ' + styles.reportNameMsg}>
                            <span className={styles.checkmark}>
                                <IconCheckmark />
                            </span>
                            &nbsp;&nbsp;Report name &quot;{reportName}&quot; is available.
                        </p>
                    ))}
                {showErrors()}
                <button
                    type="button"
                    className={`btn btn-primary ${styles.submit}`}
                    disabled={!!error.reportName}
                    onClick={handleSubmit}
                >
                    Save Report
                </button>
            </div>
        );
    }

    return (
        <div>
            <Dropzone
                accept=".zip"
                type="file"
                className={styles.dropzone}
                disablePreview
                multiple={false}
                onDrop={onFileDrop}
            >
                <p className={styles.dropzoneDescription}>Drag and drop your file here (click to browse).</p>
            </Dropzone>
        </div>
    );
};

Form.propTypes = {
    existingReportNames: PropTypes.array,
    onSuccess: PropTypes.func.isRequired,
    projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

Form.defaultProps = {
    existingReportNames: [],
};

export default Form;
