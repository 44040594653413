import copy from 'copy-to-clipboard';
import React from 'react';

import notification from 'cccisd-notification';

/*
 * Displays the upload button
 *
 */
export default settings => {
    return ({ value, row, loadData }) => {
        const pawnHash = row['pawn.pawnHash'];
        const copyText = event => {
            event.preventDefault();
            copy(pawnHash);

            notification({
                message: 'API access key copied successfully!',
                type: 'success',
                duration: 8000,
            });
        };

        return (
            <button type="button" onClick={copyText}>
                <span className="glyphicon glyphicon-lock" />
            </button>
        );
    };
};
