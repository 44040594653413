import { createAction, handleActions } from 'redux-actions';

export const persistedKeys = ['isSecure', 'lastLogin'];

export const initialState = {
    isSecure: false,
    lastLogin: '',
};

// actions
const SET_SECURE = 'app/security/SET_SECURE';
const SET_INSECURE = 'app/security/SET_INSECURE';

// action creators
export const setSecure = createAction(SET_SECURE);
export const setInsecure = createAction(SET_INSECURE);

// reducers
export default handleActions(
    {
        [SET_SECURE]: (state, action) => ({
            ...state,
            isSecure: true,
            lastLogin: window.cccisd.fortress.user.actual.user.last_login,
        }),
        [SET_INSECURE]: (state, action) => ({
            ...state,
            isSecure: false,
            lastLogin: window.cccisd.fortress.user.actual.user.last_login,
        }),
    },
    initialState
);
