import React, { useState } from 'react';
import IconRight from 'cccisd-icons/arrow-right5';
import IconDown from 'cccisd-icons/arrow-down5';
import IconImage from 'cccisd-icons/image4';
import style from './styles.css';

const ReportTable = ({ year, startExpanded, isOnlySection }) => {
    // if it's the only section on the page, it is not collapsable
    const [isExpanded, setIsExpanded] = useState(startExpanded);

    function getHeaderText() {
        if (year.label) {
            return year.label;
        }

        return (
            `${year.startISO.slice(0, 4)}-${year.endISO ? year.endISO.slice(0, 4) : 'Current'}` +
            ' Reports'
        );
    }

    function renderReportContainer() {
        // If both .pdf and .html exist in same report, we only want to show the .html
        // Otherwise, we can show either
        const hidePdfPaths = {};
        year.files.forEach((f, i) => {
            if (f.filename.endsWith('.pdf')) {
                if (hidePdfPaths[f.folder.path]) {
                    return;
                }

                const isHtmlInSameReport = year.files.some(
                    otherFile =>
                        otherFile.folder.path === f.folder.path &&
                        otherFile.filename.endsWith('.html')
                );
                if (isHtmlInSameReport) {
                    hidePdfPaths[f.folder.path] = true;
                    return;
                }
            } else if (f.filename.endsWith('.html')) {
                hidePdfPaths[f.folder.path] = true;
            }

            f.pathParts = f.folder.path.split('/');
            if (f.pathParts.length < 7) {
                return;
            }

            f.reportName = f.pathParts[4];
            f.hrefPath = f.pathParts.slice(3);
            f.hrefPath.unshift('api/app/reports');
        });

        return (
            <div className={'row ' + style.reportContainer}>
                {year.files.map((f, index) => {
                    if (hidePdfPaths[f.folder.path] && f.filename.endsWith('.pdf')) {
                        return null;
                    }

                    const showFilename = year.files.some(
                        (otherF, j) => f.reportName === otherF.reportName && index !== j
                    );

                    return (
                        <div key={index} className="col-12 col-sm-6 col-lg-4">
                            <a
                                className={style.reportTile}
                                href={`${f.hrefPath.join('/')}/${f.filename}`}
                                rel="noopener noreferrer"
                                target="_BLANK"
                                type="btn"
                            >
                                <span className={style.icon}>
                                    <IconImage />
                                </span>
                                <div className={style.reportName}>
                                    {f.reportName.replace(/_/g, ' ')}
                                    {showFilename && (
                                        <span className={style.filename}>{f.filename}</span>
                                    )}
                                </div>
                            </a>
                        </div>
                    );
                })}
            </div>
        );
    }

    if (isOnlySection) {
        return renderReportContainer();
    }

    return (
        <li className={style.reportListItem} key={year.startISO}>
            <h6>
                <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => setIsExpanded(prev => !prev)}
                >
                    {isExpanded ? <IconDown /> : <IconRight />}&nbsp;&nbsp;
                    {getHeaderText()}
                </button>
            </h6>
            {isExpanded && (
                <>
                    {renderReportContainer()}
                    <button
                        type="button"
                        className={'btn btn-link btn-xs ' + style.collapseButton}
                        onClick={() => setIsExpanded(false)}
                    >
                        Collapse
                    </button>
                </>
            )}
        </li>
    );
};

export default ReportTable;
