import React from 'react';
import IconCheck from 'cccisd-icons/checkmark4';
import IconBlocked from 'cccisd-icons/blocked';

import { RATING_FORM_ASSIGNMENT_HANDLE } from '../../components/TeacherAdministrative';

/*
 * Displays the upload button
 *
 */
export default settings => {
    return ({ isCsv, row }) => {
        const { timepoint, field } = settings;

        let isSchoolLevelPlan;
        try {
            isSchoolLevelPlan = row['ancestorGroups.site.selectedAssignmentPlanList'].some(
                plan => plan.assignmentPlanId
            );
        } catch (e) {
            // probably just undefined
            isSchoolLevelPlan = false;
        }

        let isLegacyRatingForm = false;
        let isRatingForm = false;
        let selectedPlans = [];
        if (isSchoolLevelPlan) {
            selectedPlans = row['ancestorGroups.site.selectedAssignmentPlanList'].map(plan => plan.label);
            try {
                isLegacyRatingForm = row['ancestorGroups.site.selectedAssignmentPlanList'].some(plan =>
                    plan.sessionList.some(
                        sess =>
                            sess.deployment.assignment.assignmentHandle !== RATING_FORM_ASSIGNMENT_HANDLE &&
                            parseInt(sess.timepoint, 10) === parseInt(timepoint, 10)
                    )
                );
            } catch (e) {
                // probably not
                isLegacyRatingForm = false;
            }

            try {
                isRatingForm = row['ancestorGroups.site.selectedAssignmentPlanList'].some(plan =>
                    plan.sessionList.some(
                        sess =>
                            sess.deployment.assignment.assignmentHandle === RATING_FORM_ASSIGNMENT_HANDLE &&
                            parseInt(sess.timepoint, 10) === parseInt(timepoint, 10)
                    )
                );
            } catch (e) {
                // probably not
                isRatingForm = false;
            }
        }
        // Look for district-default assignment plans
        else {
            try {
                const legacyDefaultPlanId =
                    row['ancestorGroups.groupingUnit.group.settings.defaultAssignmentPlans.plan_teacher'];

                const legacySelectedPlan = row['ancestorGroups.organization.createdAssignmentPlanList'].find(
                    plan => parseInt(plan.assignmentPlanId, 10) === parseInt(legacyDefaultPlanId, 10)
                );

                selectedPlans.push(legacySelectedPlan.label);

                isLegacyRatingForm = legacySelectedPlan.sessionList.some(
                    sess =>
                        sess.deployment.assignment.assignmentHandle !== RATING_FORM_ASSIGNMENT_HANDLE &&
                        parseInt(sess.timepoint, 10) === parseInt(timepoint, 10)
                );
            } catch (e) {
                // probably not
                isLegacyRatingForm = false;
            }

            try {
                const defaultPlanId =
                    row['ancestorGroups.groupingUnit.group.settings.defaultAssignmentPlans.plan_studentRatingForm'];

                const selectedPlan = row['ancestorGroups.organization.createdAssignmentPlanList'].find(
                    plan => parseInt(plan.assignmentPlanId, 10) === parseInt(defaultPlanId, 10)
                );

                selectedPlans.push(selectedPlan.label);

                isRatingForm = selectedPlan.sessionList.some(
                    sess =>
                        sess.deployment.assignment.assignmentHandle === RATING_FORM_ASSIGNMENT_HANDLE &&
                        parseInt(sess.timepoint, 10) === parseInt(timepoint, 10)
                );
            } catch (e) {
                // probably not
                isRatingForm = false;
            }
        }

        if (field === 'plan') {
            return isCsv ? (
                selectedPlans.join(',')
            ) : (
                <ul style={{ paddingLeft: 20 }}>
                    {selectedPlans.map((label, i) => (
                        <li key={i}>{label}</li>
                    ))}
                </ul>
            );
        }

        if (!isLegacyRatingForm && !isRatingForm) {
            return isCsv ? '' : <span />;
        }

        let isLegacyFormStarted = isLegacyRatingForm && !!row['legacyRatingFormProgress.startedDate'];
        let isLegacyFormComplete = !isLegacyRatingForm || !!row['legacyRatingFormProgress.completedDate'];

        let isRatingFormStarted;
        let isRatingFormComplete;
        try {
            const classList = row['ratingFormProgress.classList'];
            isRatingFormStarted = isRatingForm && classList.some(c => c?.childRoles?.existsStarted?.pawn?.pawnId);
            isRatingFormComplete =
                !isRatingForm || !classList.some(c => c?.childRoles?.existsNotCompleted?.pawn?.pawnId);
        } catch (e) {
            isRatingFormStarted = isRatingForm;
            isRatingFormComplete = !isRatingForm;
        }

        let isStarted = isLegacyFormStarted || isRatingFormStarted;
        let isComplete = isLegacyFormComplete && isRatingFormComplete;

        if (field === 'started') {
            if (isCsv) {
                return isStarted ? 'yes' : 'no';
            }
            return isStarted ? (
                <span className="text-success">
                    <IconCheck />
                </span>
            ) : (
                <span className="text-danger">
                    <IconBlocked />
                </span>
            );
        }

        if (field === 'complete') {
            if (isCsv) {
                return isComplete ? 'yes' : 'no';
            }
            return isComplete ? (
                <span className="text-success">
                    <IconCheck />
                </span>
            ) : (
                <span className="text-danger">
                    <IconBlocked />
                </span>
            );
        }

        return isCsv ? '' : <span />;
    };
};
