import React from 'react';
import Modal from 'cccisd-modal';
import PropTypes from 'prop-types';
import ProvisionalForm from 'js/components/ProvisionalForm';

export default class Component extends React.Component {
    static propTypes = {
        loadData: PropTypes.func,
    };

    render() {
        return (
            <Modal
                trigger={
                    <button id="addProvisionalStudent" type="button" className="btn btn-primary">
                        Add Provisional Student
                    </button>
                }
                size="large"
                title="Add Provisional Student"
            >
                <ProvisionalForm loadData={this.props.loadData} />
            </Modal>
        );
    }
}
