import React from 'react';
import { isPast, isFuture } from 'date-fns';
import ClickButton from 'cccisd-click-button';
import Axios from 'cccisd-axios';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;

function launchStudent(row) {
    var fd = new FormData();
    fd.append('pawnId', row['pawn.pawnId']);
    fd.append('pawnHash', row['pawn.pawnHash']);
    Axios.post(Boilerplate.route('api.nexus.pawn.changeTo'), fd).then(response => {
        Axios.post(Boilerplate.route('app.lastLogin')).then(response2 => {
            window.location = Boilerplate.settings.publicUrl + 'student';
        });
    });
}

/*
 * Displays the student launcher button or status, depending on conditions
 */
export default settings => {
    return ({ value, row, loadData }) => {
        const time = settings.time;
        const grade = row['fields.grade'];
        let currentTimepoint =
            row['ancestorGroups.class.selectedAssignmentPlan.currentSession.deployment.timepoint'];

        // check for District defaults only if none selected at Class level
        if (
            !currentTimepoint &&
            !row['ancestorGroups.class.selectedAssignmentPlan.assignmentPlanId']
        ) {
            const defaultPlanId =
                row[
                    'ancestorGroups.groupingUnit.group.settings.defaultAssignmentPlans.plan_' +
                        grade
                ] || '-1';

            const defaultPlan = (
                row['ancestorGroups.organization.createdAssignmentPlanList'] || []
            ).find(
                assignmentPlan => Number(assignmentPlan.assignmentPlanId) === Number(defaultPlanId)
            );

            const defaultSession = (defaultPlan?.sessionList || []).find(
                session =>
                    Number(session.timepoint) === Number(time) &&
                    (!session.opensAt || isPast(new Date(session.opensAt))) &&
                    (!session.closesAt || isFuture(new Date(session.closesAt)))
            );

            currentTimepoint = defaultSession ? defaultSession.timepoint : -1;
        }

        if (value === 'In Progress') {
            if (time === currentTimepoint) {
                return (
                    <div className={style.statusButton}>
                        <ClickButton
                            className="btn btn-primary"
                            title="Resume"
                            onClick={launchStudent.bind(this, row)}
                        />
                    </div>
                );
            }
            return (
                <div className={style.statusButton}>
                    <div>Incomplete</div>
                </div>
            );
        }
        if (value === 'Complete') {
            return (
                <div className={style.statusButton}>
                    <div>
                        <span className={style.statusDoneIcon + ' glyphicon glyphicon-ok'} />{' '}
                        {row['time' + time + 'Progress.completedDate']}
                    </div>
                </div>
            );
        }
        if (time === currentTimepoint) {
            return (
                <div className={style.statusButton}>
                    <div>
                        <ClickButton
                            className="btn btn-primary"
                            title="Start"
                            onClick={launchStudent.bind(this, row)}
                        />
                    </div>
                </div>
            );
        }
        return (
            <div className={style.statusButton}>
                <div>&mdash;</div>
            </div>
        );
    };
};
