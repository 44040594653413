import React, { useState } from 'react';
import { compareAsc, parseISO } from 'date-fns';
import IconInfo from 'cccisd-icons/info';
import ReportContainer from './ReportContainer.js';
import styles from './styles.css';

const Appdefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

const ReportList = ({ fileList, orgAdminDropdown }) => {
    const isOrgAdmin = Fortress.user.acting.role.handle === 'orgAdmin';
    const [currDistrict, setCurrDistrict] = useState(
        isOrgAdmin && Array.isArray(orgAdminDropdown) && orgAdminDropdown.length > 0 && orgAdminDropdown[0].groupId
    );

    const schoolYears = Appdefs.app.schoolYears || [];
    schoolYears.forEach(year => {
        year.files = [];
    });

    if (isOrgAdmin && currDistrict) {
        const re = new RegExp(`districts/${currDistrict}$`);
        fileList = fileList.filter(f => re.test(f.folder.path));
    }

    fileList.forEach(file => {
        const created = new Date(file.createdAt);
        let schoolYearIndex = schoolYears.findIndex(year => compareAsc(created, parseISO(year.startISO)) >= 0);
        if (schoolYearIndex === -1) {
            schoolYearIndex = schoolYears.length - 1;
        }

        schoolYears[schoolYearIndex].files.push(file);
    });

    const yearsWithFiles = schoolYears.filter(year => year.files.length > 0);

    const districtSelector = isOrgAdmin && (
        <form className={'form-inline ' + styles.districtSelector}>
            <div className="form-group">
                <label htmlFor="district">District: </label>
                <select
                    className="form-control"
                    name="district"
                    id="district"
                    onChange={event => setCurrDistrict(event.target.value)}
                    value={currDistrict}
                >
                    {orgAdminDropdown.map(district => (
                        <option key={district.groupId} value={district.groupId}>
                            {district.label}
                        </option>
                    ))}
                </select>
            </div>
        </form>
    );

    if (fileList.length === 0) {
        return (
            <>
                {districtSelector}
                <div className={'alert alert-info ' + styles.noReports}>
                    <p>
                        <span className={styles.icon}>
                            <IconInfo />
                        </span>
                        No reports to show at this time.
                    </p>
                </div>
            </>
        );
    }

    if (yearsWithFiles.length === 0) {
        return (
            <>
                {districtSelector}
                <p className="text-muted">There are no reports to show at this time.</p>
            </>
        );
    }

    return (
        <>
            {districtSelector}
            <ul className={styles.reportList}>
                {yearsWithFiles.map((year, i) => (
                    <ReportContainer
                        year={year}
                        key={year.startISO}
                        startExpanded={i === 0}
                        isOnlySection={
                            yearsWithFiles.length === 1 && yearsWithFiles[0].startISO === schoolYears[0].startISO
                        }
                    />
                ))}
            </ul>
        </>
    );
};

export default ReportList;
