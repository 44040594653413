import React from 'react';
import Loader from 'cccisd-loader';
import eeStyle from './eeStyle.css';
import leStyle from './leStyle.css';
import studentQuery from './studentQuery.graphql';
import { Link } from 'react-router-dom';
import { client as apollo } from 'cccisd-apollo';
import bear from './bear.png';
import StudentCommon from '../StudentCommon';
import commonStyle from '../StudentCommon/style.css';

export default class StudentLanding extends StudentCommon {
    getStudentInfo = async () => {
        let result = await apollo.query({
            query: studentQuery,
            fetchPolicy: 'network-only',
        });

        let student = result.data.roles.learner;
        if (!student) {
            this.setState({ loading: false, error: true });
            return;
        }

        let name = student.user.fullName;
        if (typeof name !== 'string' || name.trim() === '') {
            name = student.user.username;
        }

        // If no plan selected at Class level, check for District defaults
        let session;
        if (!student?.parentGroup?.class?.selectedAssignmentPlan?.assignmentPlanId) {
            let grade = student.fields.grade.toString().toLowerCase();
            if (grade === 'k') {
                grade = '0';
            }
            const defaultPlanId = (student?.ancestorGroups?.groupingUnit?.group?.settings
                ?.defaultAssignmentPlans || {})['plan_' + grade];
            const availablePlans =
                student?.ancestorGroups?.organization?.createdAssignmentPlanList || [];
            const planDef =
                defaultPlanId &&
                availablePlans.find(
                    (plan) =>
                        plan?.role === 'learner' &&
                        plan?.assignmentPlanId === parseInt(defaultPlanId, 10)
                );

            session = planDef?.currentSession?.sessionId ? planDef.currentSession : '';
        } else {
            const currentSession =
                student?.parentGroup?.class?.selectedAssignmentPlan?.currentSession || '';
            session = currentSession?.sessionId ? currentSession : '';
        }

        const assessmentHeader = session?.deployment?.assignment?.settings?.selwebHeader || '';
        this.setState({ loading: false, name, session, assessmentHeader });
    };

    isEE = () => {
        const { session } = this.state;
        return !!session.settings.options.find((value) => value.startsWith('ee_'));
    };

    showConfirmation = () => {
        if (this.isEE()) {
            return this.showEEConfirmation();
        }
        return this.showLEConfirmation();
    };

    showEEConfirmation = () => (
        <>
            {this.showSelHeader()}
            <div className={`${commonStyle.wrapper} ${eeStyle.wrapper} ${eeStyle.ee}`}>
                <div className={eeStyle.assistantWrapper}>
                    <img className={eeStyle.assistant} src={bear} alt="bear" />
                </div>
                <div className={eeStyle.speechWrapper}>
                    <div className={eeStyle.speechBubble}>
                        <span className={eeStyle.question}>Welcome!&nbsp;Are&nbsp;you&nbsp;</span>
                        <span className={eeStyle.name}>{this.state.name}</span>?
                    </div>
                    <div className={`${commonStyle.buttons} ${eeStyle.buttons}`}>
                        <Link to="studentPlay">
                            <button
                                className={'btn btn-lg btn-primary ' + commonStyle.yes}
                                type="button"
                            >
                                Yes
                            </button>
                        </Link>
                        <button
                            className={'btn btn-lg btn-danger ' + commonStyle.no}
                            type="button"
                            onClick={() => {
                                window.location =
                                    window.cccisd.boilerplate.route('api.nexus.logout');
                            }}
                        >
                            No
                        </button>
                    </div>
                </div>
            </div>
        </>
    );

    showLEConfirmation = () => (
        <>
            {this.showSelHeader()}
            <div className={`${commonStyle.wrapper} ${leStyle.wrapper}`}>
                <div className={commonStyle.question}>
                    Welcome!&nbsp;Are&nbsp;you&nbsp;
                    <span className={commonStyle.name}>{this.state.name}</span>?
                </div>
                <div className={`${commonStyle.buttons} ${leStyle.buttons}`}>
                    <Link to="studentPlay">
                        <button
                            className={'btn btn-lg btn-primary ' + commonStyle.yes}
                            type="button"
                        >
                            Yes
                        </button>
                    </Link>
                    <button
                        className={'btn btn-lg btn-danger ' + commonStyle.no}
                        type="button"
                        onClick={() => {
                            window.location = window.cccisd.boilerplate.route('api.nexus.logout');
                        }}
                    >
                        No
                    </button>
                </div>
            </div>
        </>
    );

    render() {
        const { loading, error, session } = this.state;

        if (loading) {
            return <Loader loading />;
        }

        if (error) {
            return this.showError();
        }

        if (!session) {
            return this.showNoSession();
        }

        return this.showConfirmation();
    }
}
