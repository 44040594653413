import React from 'react';

const Boilerplate = window.cccisd.boilerplate;

export default class StudentRedirect extends React.Component {
    componentDidMount() {
        const redirectUrl = Boilerplate.url('/student');
        window.location = redirectUrl;
    }

    render() {
        const redirectUrl = Boilerplate.url('/student');
        return (
            <div>
                <a href={redirectUrl}>Redirecting...</a>
            </div>
        );
    }
}
