import React from 'react';
import Loader from 'cccisd-loader';
import PropTypes from 'prop-types';
import ClickButton from 'cccisd-click-button';
import Axios from 'cccisd-axios';

const Boilerplate = window.cccisd.boilerplate;

export default class RescoreButton extends React.Component {
    static propTypes = {
        loadData: PropTypes.func,
        row: PropTypes.object,
        value: PropTypes.string,
        settings: PropTypes.object,
    };

    static defaultProps = {
        loadData: null,
        row: [],
        value: null,
        settings: {},
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };

        this.rescore = this.rescore.bind(this);
    }

    rescore() {
        const { row, settings, loadData } = this.props;

        this.setState({ loading: true });
        var fd = new FormData();
        fd.append('pawnId', row['pawn.pawnId']);
        fd.append('pawnHash', row['pawn.pawnHash']);
        fd.append('deploymentId', row['t' + settings.timepoint + '.deployment.deploymentId']);
        fd.append('module', 'all');
        fd.append('rescore', true);
        Axios.post(Boilerplate.route('api.selweb' + settings.assessment + '.score'), fd).then(
            response => {
                loadData();
                this.setState({ loading: false });
            }
        );
    }

    render() {
        const { loading } = this.state;
        const { value } = this.props;

        if (value) {
            return (
                <div style={{ whiteSpace: 'nowrap' }}>
                    <div>
                        {value}
                        <Loader loading={loading}>
                            <ClickButton
                                className="btn btn-primary btn-xs"
                                style={{ marginLeft: '10px' }}
                                title="Rescore"
                                isLoading={false}
                                onClick={this.rescore}
                            />
                        </Loader>
                    </div>
                </div>
            );
        }
        return (
            <div style={{ whiteSpace: 'nowrap' }}>
                <div>&mdash;</div>
            </div>
        );
    }
}
