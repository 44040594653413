import React from 'react';
import RescoreButton from './rescoreButton.js';

/*
 * Displays the student launcher button or status, depending on conditions
 */
export default settings => {
    return ({ value, row, loadData }) => {
        return <RescoreButton settings={settings} value={value} row={row} loadData={loadData} />;
    };
};
