import React from 'react';
import style from './style.css';
import ClickButton from 'cccisd-click-button';
import IconCross from 'cccisd-icons/cross3';
import IconCheckmark from 'cccisd-icons/checkmark';
import IconWarning from 'cccisd-icons/warning';
import IconPlay from 'cccisd-icons/play3';
import IconStop from 'cccisd-icons/stop';

export default class BrowserTest extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            imageLoading: true,
            randomNumber: Math.floor(Math.random() * 1000000),
            imageSpeed: -1,
            imageSize: 0,
            imageError: '',
            audioPlaying: false,
        };
    }

    getImageFile() {
        this;
        return 'https://dm0gz550769cd.cloudfront.net/selweb-2020/fe/fee4f1823edcc6591b9845a4a32d3a9e.jpg';
    }

    getAudioFile() {
        this;
        return 'https://dm0gz550769cd.cloudfront.net/selweb-2020/6c/6c085b22b5292f00f71ee7d9ae86f7a1.mp3';
    }

    imageLoaded() {
        var resourceList = window.performance.getEntriesByType('resource');
        for (let i = 0; i < resourceList.length; i++) {
            if (resourceList[i].entryType === 'resource') {
                const resource = resourceList[i];
                const resourceUrl = resource.name.split('/');
                const resourcePath = resourceUrl[resourceUrl.length - 1];

                if (resourcePath.endsWith('?' + this.state.randomNumber)) {
                    let imageSize = resource.transferSize;
                    if (!imageSize) {
                        imageSize = 1208299; // hardcoded image size since it's not reliably returned
                    }
                    imageSize /= 1000000.0;

                    const duration = (resource.responseEnd - resource.startTime) / 1000.0;

                    let imageSpeed = (imageSize * 8) / duration;

                    imageSpeed = imageSpeed.toFixed(2);
                    imageSize = imageSize.toFixed(2);

                    this.setState({
                        imageSpeed,
                        imageSize,
                    });
                }
            }
        }
        this.setState({
            imageLoading: false,
        });
    }

    imageError(e) {
        this.setState({
            imageLoading: false,
            imageError: 'Did not load',
        });
    }

    audioTest() {
        const { audioPlaying } = this.state;
        let audio = document.getElementById('audio');

        if (audioPlaying) {
            audio.pause();
            audio.currentTime = 0;
        } else {
            audio.play();
        }
        this.setState({ audioPlaying: !audioPlaying });
    }

    audioEnded() {
        let audio = document.getElementById('audio');

        audio.pause();
        audio.currentTime = 0;

        this.setState({ audioPlaying: false });
    }

    showImage() {
        return (
            <img
                className={style.image}
                id="image"
                src={this.getImageFile() + '?' + this.state.randomNumber}
                onLoad={() => {
                    this.imageLoaded();
                }}
                onError={e => {
                    this.imageError(e);
                }}
                alt=""
            />
        );
    }

    showAudio() {
        return (
            <audio
                id="audio"
                style={{ display: 'none' }}
                src={this.getAudioFile()}
                onEnded={() => {
                    this.audioEnded();
                }}
            >
                <track kind="captions" />
            </audio>
        );
    }

    showFeedback() {
        const { imageLoading, imageSpeed, imageSize, imageError } = this.state;

        if (imageLoading) {
            return <div>Image loading...</div>;
        }
        if (imageError !== '') {
            return (
                <div>
                    <span style={{ color: 'red' }}>
                        <IconCross />
                    </span>{' '}
                    <b>Error loading image from cloudfront.net</b>
                    <br />
                    <a style={{ whiteSpace: 'nowrap' }} href={this.getImageFile()}>
                        {this.getImageFile()}
                    </a>
                </div>
            );
        }
        let speedFeedback = '(speed test not available)';
        let bandwidth = 'Bandwidth detection not available';
        if (imageSpeed > 0) {
            speedFeedback = '(' + imageSize + ' MB at ' + imageSpeed + ' mbps)';
            if (imageSpeed >= 10) {
                bandwidth = (
                    <div>
                        Detected bandwidth: <span style={{ color: 'green' }}>Excellent</span>
                    </div>
                );
            } else if (imageSpeed >= 5) {
                bandwidth = (
                    <div>
                        Detected bandwidth: <span style={{ color: 'green' }}>Good</span>
                    </div>
                );
            } else if (imageSpeed >= 2) {
                bandwidth = (
                    <div>
                        Detected bandwidth: <span style={{ color: 'green' }}>OK</span>
                    </div>
                );
            } else if (imageSpeed >= 1) {
                bandwidth = (
                    <div>
                        Detected bandwidth: <span style={{ color: 'orange' }}>Low</span>
                        <br />
                        <span style={{ color: 'orange' }}>
                            <IconWarning />
                        </span>{' '}
                        Students may experience some slowness moving from page to page.
                    </div>
                );
            } else {
                bandwidth = (
                    <div>
                        Detected bandwidth: <span style={{ color: 'red' }}>Very Low</span>.<br />
                        <span style={{ color: 'red' }}>
                            <IconWarning />
                        </span>{' '}
                        Students will likely experience slowness and interruptions.
                    </div>
                );
            }
        }
        return (
            <>
                <div className={style.imageFeedback}>
                    <span style={{ color: 'green' }}>
                        <IconCheckmark />
                    </span>{' '}
                    Loaded image from cloudfront.net successfully {speedFeedback}
                </div>
                {bandwidth}
            </>
        );
    }

    render() {
        const { audioPlaying } = this.state;

        return (
            <div className={style.wrapper}>
                <div className={style.imageBox}>
                    {this.showImage()}
                    {this.showAudio()}
                </div>
                <div className={style.useragent}>{navigator.userAgent}</div>
                <div className={style.feedback}>{this.showFeedback()}</div>
                <div className={style.execute}>
                    <ClickButton
                        className="btn btn-primary"
                        onClick={() => {
                            this.audioTest();
                        }}
                        title={
                            audioPlaying ? (
                                <span>
                                    <IconStop /> Stop Audio
                                </span>
                            ) : (
                                <span>
                                    <IconPlay /> Audio Test
                                </span>
                            )
                        }
                    />
                </div>
            </div>
        );
    }
}
