import React from 'react';
import { client as apollo } from 'cccisd-apollo';
import { HashLink as Link } from 'react-router-hash-link';
import Loader from 'cccisd-loader';
import style from './style.css';
import query from './assignmentPreviewList.graphql';

export default class AssignmentPreviewList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            assignments: [],
            loading: true,
        };

        this.getFlowList();
    }

    async getFlowList() {
        let result = await apollo.query({
            query,
            fetchPolicy: 'network-only',
        });

        let assignments = [];
        const assignmentList = result.data.flows.assignmentList;
        assignmentList.forEach(assignment => {
            const surveys = assignment.surveyList;
            let flows = [];

            surveys.forEach(survey => {
                survey.flowList.forEach(flow => {
                    flows.push(flow);
                });
            });

            // Remove flows with duplicate handles
            flows = flows.filter(
                (flow, index, self) =>
                    index ===
                    self.findIndex(
                        f => f.flowHandle === flow.flowHandle && f.language === flow.language
                    )
            );
            let a = {
                assignment,
                flows,
            };
            assignments.push(a);
        });

        this.setState({
            assignments,
            loading: false,
        });
    }

    renderAssignment(assignment) {
        var flowList = [];
        Object.keys(assignment.flows).forEach(idx => {
            let flow = assignment.flows[idx];
            flowList.push(
                <li key={idx}>
                    <Link
                        to={
                            'previewFlow#' +
                            flow.flowId +
                            '-' +
                            flow.survey.label.replace(/ /g, '') +
                            '(' +
                            flow.language +
                            ')'
                        }
                    >
                        {flow.survey.label} ({flow.language})
                    </Link>
                </li>
            );
        }, this);

        return (
            <div key={assignment.assignment.assignmentId} style={{ marginBottom: '2em' }}>
                <h3>{assignment.assignment.group.group.label}</h3>
                {flowList}
            </div>
        );
    }

    render() {
        const { assignments, loading } = this.state;

        if (loading) {
            return <Loader loading />;
        }

        let assignmentList = [];
        assignments.forEach(a => {
            assignmentList.push(this.renderAssignment(a));
        });
        return (
            <div className={style.wrapper}>
                <div>
                    <ul>{assignmentList}</ul>
                </div>
            </div>
        );
    }
}
