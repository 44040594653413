import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
const hostName = window.location.hostname;
const Footer = props => {
    return (
        <div className={style.footer}>
            <div className={props.className}>
                <div className={style.wrapper}>
                    <div>
                        Powered by{' '}
                        <a
                            href={`//www.3cisd.com?r=${hostName}`}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            3C Institute.
                        </a>
                    </div>
                    {props.showPolicyLinks && (
                        <div className={style.links}>
                            <a
                                href="https://xsel-labs.com/terms-of-use/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Terms of Use
                            </a>
                            <a
                                href="https://xsel-labs.com/privacy/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Privacy Policy
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

Footer.propTypes = {
    className: PropTypes.string,
    showPolicyLinks: PropTypes.bool,
};

Footer.defaultProps = {
    className: 'container',
    showPolicyLinks: true,
};

export default Footer;
