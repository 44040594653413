import React from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import Axios from 'cccisd-axios';
import flatten from 'flat';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import Confirm from 'cccisd-confirm';
import { Formik, Form, Field, CccisdDatepicker, CccisdInput, CccisdSelect } from 'cccisd-formik';
import Loader from 'cccisd-loader';
import notification from 'cccisd-notification';
import Table from 'cccisd-table';
import style from './style.css';

import matchNameQuery from './matchNameQuery.graphql';
import matchStudentIdQuery from './matchStudentIdQuery.graphql';
import classDataQuery from './classDataQuery.graphql';

const Appdefs = window.cccisd.appDefs;
const Boilerplate = window.cccisd.boilerplate;

export default class ProvisionalForm extends React.Component {
    static propTypes = {
        loadData: PropTypes.func,
        closeModal: PropTypes.func,
    };

    state = {
        matchDistrictId: undefined,
        matchFirstName: '',
        matchLastName: '',
        matchNameData: [],
        matchStudentId: undefined,
        matchStudentIdData: [],
        classId: null,
        classData: [],
        classDataLoading: true,
    };

    /* /////////////////////////////////////////////////////////////////////////
    // REDUX LIFECYCLE METHODS ////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    componentDidMount() {
        this.loadClassData();
    }

    /* /////////////////////////////////////////////////////////////////////////
    // CLASS SELECTION ////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    loadClassData = async () => {
        if (this.state.classDataLoading) {
            const response = await apollo.query({
                query: classDataQuery,
                fetchPolicy: 'network-only',
            });
            const classData = response.data.groups.classList.map(obj =>
                flatten(obj, { safe: true })
            );
            this.setState({
                classData,
                classDataLoading: false,
            });
        }
    };

    /**
     * Render the class table (with district limited, if present).
     *
     * @param func setFieldValue Comes from Formik
     *
     * @return node The class selection table
     */
    classSelector = ({ setFieldValue, errors, values }) => {
        const fieldName = 'group';
        const gqlClassId = 'group.groupId';
        const error = errors[fieldName];
        const columns = [
            {
                name: 'group.label',
                label: 'Class Name',
                filter: true,
                sort: true,
            },
            {
                name: 'ancestorGroups.site.group.label',
                label: 'School Name',
                filter: true,
                sort: true,
            },
            {
                name: 'ancestorGroups.groupingUnit.group.label',
                label: 'District Name',
                filter: true,
                sort: true,
            },
            {
                name: 'ancestorGroups.site.fields.city',
                label: 'City',
                filter: true,
                sort: true,
            },
            {
                name: 'ancestorGroups.site.fields.state',
                label: 'State',
                filter: true,
                sort: true,
            },
        ];

        return (
            <Loader loading={this.state.classDataLoading}>
                <div className={`form-group ${error ? 'has-error' : ''}`}>
                    <span className={[style.inputLabel, error ? style.inputError : ''].join(' ')}>
                        Class: *
                    </span>
                    <Table
                        onRowClick={async row => {
                            await setFieldValue(fieldName, row[gqlClassId]);
                            this.setState({
                                matchDistrictId: row['ancestorGroups.groupingUnit.group.groupId'],
                            });
                        }}
                        setRowClass={row => {
                            if (values[fieldName] === row[gqlClassId]) {
                                return 'info';
                            }
                        }}
                        rowKey={gqlClassId}
                        data={this.state.classData}
                        columns={columns}
                    />
                    {error && <div className="help-block">{error}</div>}
                </div>
            </Loader>
        );
    };

    /* /////////////////////////////////////////////////////////////////////////
    // MATCH TABLE ////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    getMatchesStudentId = _debounce(async studentId => {
        const { matchDistrictId } = this.state;
        if (!matchDistrictId || !studentId) {
            if (!studentId && this.state.matchStudentIdData.length) {
                this.setState({ matchStudentId: undefined, matchStudentIdData: [] });
            }
            return;
        }
        const response = await apollo.query({
            query: matchStudentIdQuery,
            fetchPolicy: 'network-only',
            variables: {
                districtId: matchDistrictId,
                studentId,
            },
        });
        const studentIdData = response.data.roles.learnerList.map(obj => {
            let tempStudent = flatten(obj, { safe: true });
            tempStudent.danger = true;
            return tempStudent;
        });
        this.setState({
            matchStudentId: studentId,
            matchStudentIdData: studentIdData,
        });
    }, 800);

    getMatchesName = _debounce(async (firstName, lastName) => {
        const { matchDistrictId, matchNameData: existingMatchNameData } = this.state;
        if (!matchDistrictId || !firstName || !lastName) {
            if (existingMatchNameData.length) {
                this.setState({ matchNameData: [] });
            }
            return;
        }
        const response = await apollo.query({
            query: matchNameQuery,
            fetchPolicy: 'network-only',
            variables: {
                districtId: matchDistrictId,
                firstName,
                lastName,
            },
        });
        const matchNameData = response.data.roles.learnerList.map(obj =>
            flatten(obj, { safe: true })
        );
        this.setState({
            matchNameData,
            matchFirstName: firstName,
            matchLastName: lastName,
        });
    }, 800);

    matchTableColumns = [
        {
            name: 'user.firstName',
            label: 'First Name',
            filter: true,
            sort: true,
        },
        {
            name: 'user.lastName',
            label: 'Last Name',
            filter: true,
            sort: true,
        },
        {
            name: 'fields.studentId',
            label: 'Student ID',
            filter: true,
            sort: true,
        },
        {
            name: 'fields.grade',
            label: 'Grade',
            filter: true,
            sort: true,
        },
        {
            name: 'fields.birthdate',
            label: 'Birthdate',
            filter: true,
            sort: true,
        },
        {
            name: 'parentGroup.class.targetedByPawns.instructorList',
            label: 'Teacher(s)',
            filter: false,
            sort: true,
            render: values => {
                if (!values) {
                    return null;
                }
                return (
                    <ul style={{ listStyleType: 'none', paddingLeft: '0px' }}>
                        {values.map((value, i) => {
                            return (
                                <li role="presentation" key={i}>
                                    {_get(value, 'user.fullNameWithUsername')}
                                </li>
                            );
                        })}
                    </ul>
                );
            },
        },
        {
            name: 'parentGroup.class.group.label',
            label: 'Class Name',
            filter: true,
            sort: true,
        },
        {
            name: 'ancestorGroups.site.group.label',
            label: 'School Name',
            filter: true,
            sort: true,
        },
        {
            name: 'ancestorGroups.groupingUnit.group.label',
            label: 'District Name',
            filter: true,
            sort: true,
        },
    ];

    renderMatches = values => {
        const { matchFirstName, matchLastName, matchNameData, matchStudentId, matchStudentIdData } =
            this.state;
        // Do we have values we can use to start querying for data?
        if (matchStudentId !== values.studentId) {
            this.getMatchesStudentId(values.studentId);
        }
        if (matchFirstName !== values.first_name || matchLastName !== values.last_name) {
            this.getMatchesName(values.first_name, values.last_name);
        }

        // Combine the arrays in order of precedence
        let studentMatchData = [];
        for (const ms of [...matchStudentIdData, ...matchNameData]) {
            if (!studentMatchData.find(e => e['pawn.pawnId'] === ms['pawn.pawnId'])) {
                if (ms['fields.birthdate'] === values.birthdate) {
                    ms.warning = true;
                } else {
                    ms.warning = false;
                }
                studentMatchData.push(ms);
            }
        }

        return (
            <div className={style.matchTable}>
                <h2>Matches with Existing Students</h2>
                <Table
                    rowKey="pawn.pawnId"
                    data={studentMatchData}
                    columns={this.matchTableColumns}
                    setRowClass={row => {
                        if (row.danger) {
                            return 'danger';
                        }
                        if (row.warning) {
                            return 'warning';
                        }
                    }}
                />
            </div>
        );
    };

    /* /////////////////////////////////////////////////////////////////////////
    // FORMIK METHODS /////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    onSubmit = async (values, formikBag) => {
        if (!values.birthdate || !values.studentId) {
            let missingFields = !values.birthdate ? '"birthdate"' : '';
            if (!values.studentId) {
                missingFields = missingFields
                    ? `${missingFields} and "Student ID"`
                    : '"Student ID"';
            }
            try {
                await Confirm({
                    message: 'Confirm That Fields Are Intentionally Missing',
                    description: `By clicking "Submit Anyway", you confirm that you intended to leave ${missingFields} blank`,
                    confirmLabel: 'Submit Anyway',
                    abortLabel: 'Cancel',
                });
            } catch (e) {
                return;
            }
        }

        if (!values.birthdate) {
            let year = new Date().getFullYear();
            let grade = values.grade === 'K' ? 0 : parseInt(values.grade, 10);
            year -= 5 + grade;
            values.birthdate = new Date(year, 0, 1);
            values.provisional_note += ' [Estimated birthdate]';
        }
        values.role = 'learner';
        const response = await Axios.post(Boilerplate.route('api.nexus.pawn.store'), values);
        if (response.data.errors) {
            notification({
                message: 'Error creating student',
                data: response.data.errors,
                duration: 0,
                type: 'danger',
            });

            // wrap in try catch because backend errors are not
            // guaranteed to be in key-value format
            try {
                // don't setError with key that isn't a field name or it will be invisible error (i.e. 'username')
                if (Object.keys(response.data.errors).every(k => Object.keys(values).includes(k))) {
                    formikBag.setErrors(response.data.errors);
                }
                return Promise.reject(response.data.errors);
            } catch (e) {
                // meh, they'll see the toaster
                return Promise.reject(response.data.errors);
            }
        }

        // Reset the form
        formikBag.resetForm();
        formikBag.setFieldValue('group', null);
        this.setState({
            matchNameData: [],
            matchStudentIdData: [],
            classId: null,
        });
        if (typeof this.props.loadData === 'function') {
            this.props.loadData();
        }
        if (typeof this.props.closeModal === 'function') {
            this.props.closeModal();
        }
    };

    validate = values => {
        let errors = {};

        if (!values.group) {
            errors.group = 'Class is required';
        }
        if (!values.first_name) {
            errors.first_name = 'First Name is required';
        }
        if (!values.last_name) {
            errors.last_name = 'Last Name is required';
        }
        if (!values.grade) {
            errors.grade = 'Grade is required';
        }
        if (!values.provisional_note) {
            errors.provisional_note = 'Note is required';
        }
        if (this.state.matchStudentIdData.length) {
            errors.studentId = 'A student with this ID already exists, see match below';
        }

        return errors;
    };

    /* /////////////////////////////////////////////////////////////////////////
    // RENDER /////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    render() {
        const { classId } = this.state;
        let gradeOptions = Appdefs.pawn.fields
            .find(e => e.handle === 'grade')
            .values.map(e => {
                return { value: e.value, label: e.name };
            });
        gradeOptions.unshift({ value: '', label: '--- Select grade ---' });
        return (
            <>
                <Formik
                    initialValues={{ group: classId, grade: '' }}
                    onSubmit={this.onSubmit}
                    validate={this.validate}
                    render={props => (
                        <Form>
                            <h2>Student Fields</h2>
                            {this.classSelector({
                                setFieldValue: props.setFieldValue,
                                values: props.values,
                                errors: props.errors,
                            })}
                            <Field name="studentId" component={CccisdInput} label="Student ID:" />
                            <Field
                                name="first_name"
                                component={CccisdInput}
                                label="First Name: *"
                            />
                            <Field name="last_name" component={CccisdInput} label="Last Name: *" />
                            <Field
                                name="grade"
                                component={CccisdSelect}
                                label="Grade: *"
                                options={gradeOptions}
                            />
                            <Field
                                name="birthdate"
                                component={CccisdDatepicker}
                                label="Birthdate:"
                            />
                            <Field
                                name="provisional_note"
                                component={CccisdInput}
                                label="Note: *"
                            />
                            {this.renderMatches(props.values)}
                            <button className="btn btn-primary" type="submit">
                                Create Student
                            </button>
                        </Form>
                    )}
                />
            </>
        );
    }
}
