import React from 'react';
import IconClipboard from 'cccisd-icons/clipboard6';
import Tooltip from 'cccisd-tooltip';
import Modal from 'cccisd-modal';
import Form from './Form';
import style from './style.css';

export default settings => {
    return props => {
        const { row } = props;
        return (
            <Modal
                title={`Default Assignment Plans: ${row['group.label']}`}
                trigger={
                    <Tooltip title="Configure Default Assignment Plans">
                        <button
                            type="button"
                            className={'btn btn-xs btn-default ' + style.configureBtn}
                        >
                            <IconClipboard />
                        </button>
                    </Tooltip>
                }
                render={({ closeModal }) => (
                    <Form settings={settings} {...props} closeModal={closeModal} />
                )}
            />
        );
    };
};
