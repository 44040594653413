import React from 'react';
import { Player } from 'cccisd-laravel-flow';
import style from './style.css';

export default class AssignmentPreview extends React.Component {
    constructor(props) {
        super(props);

        var flowId = 0;

        if (window.location.hash !== '') {
            flowId = parseInt(
                decodeURIComponent(window.location.hash.substring(1)).split('-', 2)[0],
                10
            );
        }

        this.state = {
            flowId,
        };
    }

    componentDidMount() {
        document.body.classList.add(style.blueGradient);
    }

    componentWillUnmount() {
        document.body.classList.remove(style.blueGradient);
    }

    render() {
        const { flowId } = this.state;

        if (flowId !== null) {
            return (
                <div>
                    <Player flowId={flowId} isPreviewMode />
                </div>
            );
        }

        return <div>No chosen flow</div>;
    }
}
