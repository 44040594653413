import React from 'react';
import Modal from 'cccisd-modal';
import ReportAdmin from '../../components/ReportAdmin';
/*
 * Displays the Report Admin for the project at group.groupId
 */
export default settings => {
    return ({ value, row, loadData }) => {
        const trigger = (
            <button type="button" className="btn btn-primary">
                Manage Reports
            </button>
        );
        return (
            <Modal size="large" title={`Report Admin for ${row['group.label']}`} trigger={trigger}>
                <ReportAdmin projectId={value} />
            </Modal>
        );
    };
};
