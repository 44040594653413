import React, { useState, useEffect } from 'react';
import { client } from 'cccisd-apollo';
import Table from 'cccisd-graphql-table';
import Loader from 'cccisd-loader';
import IconError from 'cccisd-icons/notification2';
import IconCheckmark from 'cccisd-icons/checkmark4';
import IconReport from 'cccisd-icons/file-text2';
import Modal from 'cccisd-modal';
import eventsQuery from './events.graphql';
import districtsQuery from './districts.graphql';

// TO-DO: move this Summary code to cccisd-laravel-nexus to do a proper export...
import Summary from 'cccisd-table-renders/dist/renderers/html/AutoRosterV2/SyncClasses/Summary';
import style from './style.css';

const NightlySync = () => {
    const [districtList, setDistrictList] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function getDistricts() {
            const resp = await client.query({
                query: districtsQuery,
                fetchPolicy: 'network-only',
            });

            const districtMapper = resp.data.groups.groupingUnitList.reduce((mapper, district) => {
                mapper[district.group.groupId] = district.group.label;
                return mapper;
            }, {});

            setDistrictList(districtMapper);
            setIsLoading(false);
        }
        getDistricts();
    }, []);

    function displayWarningsOrErrors(messageList, columnName) {
        return (
            <div className={`alert ${columnName === 'error' ? 'alert-danger' : 'alert-warning'}`}>
                <h5>
                    <b>
                        {messageList.length} {columnName}
                        {messageList.length === 1 ? ' was' : 's were'} found with this sync attempt.
                    </b>
                </h5>
                <ul>
                    {messageList.map((msg, i) => {
                        if (typeof msg === 'string') {
                            try {
                                msg = JSON.parse(msg);
                            } catch (e) {
                                return (
                                    <li key={i}>
                                        <p>{msg}</p>
                                    </li>
                                );
                            }
                        }

                        if (typeof msg === 'object') {
                            return (
                                <li key={i}>
                                    {msg.message && <p>{msg.message}</p>}
                                    {msg.exampleMessage && (
                                        <p className={style.errorExampleMessage}>{msg.exampleMessage}</p>
                                    )}
                                </li>
                            );
                        }

                        return null;
                    })}
                </ul>
            </div>
        );
    }

    function getErrorsFromRow(row) {
        if (
            row['metadata.errors'] &&
            Array.isArray(row['metadata.errors']) &&
            row['metadata.errors'].some(err => typeof err === 'object' && err.message)
        ) {
            return row['metadata.errors'];
        }

        return [];
    }

    function getWarningsFromRow(row) {
        if (row['metadata.warnings'] && Array.isArray(row['metadata.warnings'])) {
            return row['metadata.warnings'];
        }

        return [];
    }

    function renderStatus({ value }) {
        return (
            <span className={style.status}>
                {value === 'success' ? (
                    <span className="text-success">
                        <IconCheckmark />
                    </span>
                ) : (
                    <span className="text-danger">
                        <IconError />
                    </span>
                )}
            </span>
        );
    }

    function renderDistrict({ value }) {
        if (districtList[value]) {
            return <span>{districtList[value]}</span>;
        }
    }

    function renderWarningsOrErrors({ row, columnName }) {
        const messageList = columnName === 'error' ? getErrorsFromRow(row) : getWarningsFromRow(row);

        return messageList.length > 0 ? (
            <Modal
                title={`${row.eventAt}: ${districtList[row.targetId]}`}
                trigger={
                    <button type="button" className={'btn btn-link ' + style.trigger}>
                        <u>{messageList.length}</u>
                    </button>
                }
                render={({ closeModal }) => (
                    <div>
                        {displayWarningsOrErrors(messageList, columnName)}
                        <button className="btn btn-primary" type="button" onClick={() => closeModal()}>
                            Close
                        </button>
                    </div>
                )}
            />
        ) : (
            <span>0</span>
        );
    }

    function renderSummary(renderProps) {
        const { row } = renderProps;

        let summary = {
            warnings: row['metadata.warnings'],
            errors: row['metadata.errors'],
            byType: row['metadata.byType'],
            bySite: row['metadata.bySite'],
            byGrade: row['metadata.byGrade'],
        };

        return (
            <Modal
                trigger={
                    <button type="button" className={'btn btn-link ' + style.trigger}>
                        <u>
                            <IconReport spaceRight />
                            Summary
                        </u>
                    </button>
                }
                title={`${districtList[row.targetId]}: ${row.eventAt}`}
                render={({ closeModal }) => {
                    const unflattenedSummary = {};
                    unflattenedSummary.bySite = row['metadata.bySite'];
                    ['sites', 'siteAdmins', 'instructors', 'classes', 'learners'].forEach(entity => {
                        [
                            'created',
                            'associated',
                            'deleted',
                            'restored',
                            'updated',
                            'unchanged',
                            'warning',
                            'error',
                            'skipped',
                            'ignored',
                        ].forEach(status => {
                            if (!unflattenedSummary[entity]) {
                                unflattenedSummary[entity] = {
                                    summary: {},
                                    errors: [],
                                    warnings: [],
                                };
                            }

                            unflattenedSummary[entity].summary[status] =
                                row[`metadata.overall.${entity}.summary.${status}`] || 0;
                        });
                    });

                    return (
                        <div>
                            <Summary summary={summary} />
                            <button className="btn btn-primary" type="button" onClick={() => closeModal()}>
                                Close
                            </button>
                        </div>
                    );
                }}
            />
        );
    }

    return (
        <Loader loading={isLoading} removeChildren>
            <Table
                renders={{ renderDistrict, renderStatus, renderSummary, renderWarningsOrErrors }}
                name="nightlySync"
                query={eventsQuery}
                rowKey="eventId"
                orderBy="eventId"
                isAscentOrder={false}
                columns={[
                    {
                        name: 'eventId',
                        label: 'Event ID',
                        filter: true,
                        sort: true,
                    },
                    {
                        name: 'status',
                        label: 'Status',
                        render: 'renderStatus',
                        width: '70px',
                        filter: true,
                        sort: false, // cannot sort because we hit database memory limit
                        filterSettings: {
                            type: 'selectbox',
                            options: [
                                { label: 'Success', value: 'success' },
                                { label: 'Failure', value: 'error' },
                            ],
                        },
                    },
                    {
                        name: 'eventAt',
                        label: 'Sync Date',
                        sort: false, // cannot sort because we hit database memory limit
                        filter: true,
                        filterSettings: { type: 'date' },
                    },
                    {
                        name: 'targetId',
                        label: 'District',
                        render: 'renderDistrict',
                    },
                    {
                        name: 'eventTypeReplaced',
                        label: 'Type',
                        filter: true,
                        sort: false, // cannot sort because we hit database memory limit
                    },
                    {
                        name: 'error',
                        label: 'Errors',
                        render: 'renderWarningsOrErrors',
                        className: 'text-center',
                    },
                    {
                        name: 'warning',
                        label: 'Warnings',
                        render: 'renderWarningsOrErrors',
                        className: 'text-center',
                    },
                    {
                        name: 'summary',
                        render: 'renderSummary',
                        label: 'Summary',
                        className: 'text-center',
                    },
                    {
                        name: 'durationSeconds',
                        label: 'Duration (s)',
                        sort: false, // cannot sort because we hit database memory limit
                        className: 'text-center',
                    },
                ]}
            />
        </Loader>
    );
};

export default NightlySync;
