import React from 'react';

export default settings => {
    return ({ row }) => {
        const totalLearners = row['descendantRoles.total'];
        const completedLearners = row['descendantRoles.completed'];
        let percent = Math.round((completedLearners / totalLearners) * 100);
        if (totalLearners === 0) {
            percent = 0;
        }

        return <span>{percent}%</span>;
    };
};
